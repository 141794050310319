import { Img, Text, Heading } from "../../components";
import React from "react";

export default function PrivacyPolicySection() {
  return (
    <>
      {/* privacy policy section */}
      <div>
        <div className="flex items-start justify-end bg-white-a700 md:flex-col">
          <div className="mt-[126px] flex w-[34%] flex-col items-start md:w-full md:px-5">
            <a href="#" className="lg:text-[48px] md:text-[48px]">
              <Heading size="heading3xl" as="h1" className="text-[74px] font-extrabold text-blue_gray-900_01">
                Privacy Policy
              </Heading>
            </a>
            <Text
              size="textmd"
              as="p"
              className="w-[92%] text-[18px] font-normal leading-7 text-gray-600_01 lg:w-full lg:text-[15px] md:w-full"
            >
              We are committed to safeguarding your data and will only share it as necessary to provide our services.
              You have rights regarding your information, including access and deletion. We employ secure methods to
              ensure your data is protected.
            </Text>
          </div>
          <Img
            src="images/img_img_5.png"
            alt="Featured Image"
            className="h-[520px] w-[48%] self-center object-contain md:w-full"
          />
        </div>
      </div>
    </>
  );
}

import React from "react";

const shapes = {
  square: "rounded-[0px]",
} as const;
const variants = {
  tarUnderLineGray300: "border-b !border-gray-300 border-solid",
} as const;
const sizes = {
  xs: "h-[126px] p-3 text-[16px]",
} as const;

type TextAreaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    className: string;
    name: string;
    placeholder: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    shape: keyof typeof shapes;
    variant: keyof typeof variants | null;
    size: keyof typeof sizes;
  }>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      shape,
      size = "xs",
      variant = "tarUnderLineGray300",
      onChange,
      ...restProps
    },
    ref
  ) => {
    return (
      <textarea
        ref={ref}
        className={`${className} ${shape && shapes[shape]} ${
          size && sizes[size]
        } ${variant && variants[variant]}`}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        {...restProps}
      />
    );
  }
);

export { TextArea };

import { Box, IconButton, SvgIcon } from "@mui/material";
import { Heading, Text, Img } from "./..";
import React from "react";
import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Twitter,
  YouTube,
} from "@mui/icons-material";

interface Props {
  className?: string;
}

export default function Footer({ ...props }: Props) {
  return (
    <footer
      {...props}
      className={`${props.className} flex justify-center items-end py-2 bg-blue_gray-900_01`}
    >
      <div className="container-xs mt-[108px] flex justify-center lg:px-5 md:px-5">
        <div className="flex w-full flex-col items-start">
          <div className="flex w-[90%] items-start justify-between gap-5 lg:w-full md:w-full md:flex-col">
            <div className="flex w-[28%] flex-col gap-3.5 md:w-full">
              <Img
                src="images/img_footer_logo.svg"
                alt="Footer Logo"
                className="h-[52px] w-[238px] object-contain"
              />
              <Text
                style={{ color: "white" }}
                as="p"
                className="text-[16px] font-normal leading-[25px] lg:text-[13px]"
              >
                Address: 1 Ben Vorlich Place, Glasgow, Scotland, G53 7WX
              </Text>
              {/* <Text
                style={{color: "white"}}
                as="p"
                className="text-[16px] font-normal leading-[25px]  lg:text-[13px]"
              >
                CRN: SC553255
              </Text> */}
              <Text
                style={{ color: "white" }}
                as="p"
                className="text-[16px] font-normal leading-[25px] lg:text-[13px]"
              >
                Phone Number: 0141 363 0407
              </Text>
              <Text
                style={{color: "white"}}
                as="p"
                className="text-[16px] font-normal leading-[25px] lg:text-[13px]"
              >
                {
                  process.env.REACT_APP_EMAIL_ADDRESS ?
                  <>
                  Email: {process.env.REACT_APP_EMAIL_ADDRESS}
                  </>
                  :
                  "info@centrixconsultancy.co.uk"
                }
              </Text>

              <Box sx={{ mt: 2, ml: -1 }}>
                <IconButton
                  aria-label="Facebook"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_FACEBOOK_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  aria-label="Twitter"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_TWITTER_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </IconButton>
                <IconButton
                  aria-label="Instagram"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_INSTAGRAM_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  aria-label="LinkedIn"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_LINKEDIN_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </IconButton>

                <IconButton
                  aria-label="pinterest"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_PINTEREST_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Pinterest />
                </IconButton>
                <IconButton
                  aria-label="youtube"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_YOUTUBE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTube />
                </IconButton>
                <IconButton
                  aria-label="tik_tok"
                  sx={{ color: "white" }}
                  href={process.env.REACT_APP_TIK_TOK_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SvgIcon
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{ color: "white" }}
                  >
                    <path
                      fill="currentColor"
                      d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"
                    />
                  </SvgIcon>
                </IconButton>
              </Box>
            </div>
            <div className="mt-2.5 flex w-[52%] items-start justify-between gap-5 self-center md:w-full sm:flex-col">
              <div className="flex w-[88%] flex-col items-start gap-6 sm:w-full">
                <Heading
                  size="headinglg"
                  as="h5"
                  className="text-[22px] font-bold text-white-a700 lg:text-[18px]"
                >
                  Quick Links
                </Heading>
                <ul className="flex flex-col items-start gap-2.5">
                  <li>
                    <a href="/home">
                      <div className="flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Home Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Home
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <div className="flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="About Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          About Us
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Services Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Services
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <div className="mr-1.5 flex items-center gap-2.5 md:mr-0">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Contact Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Contact
                        </Heading>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex w-[88%] flex-col items-start gap-6 sm:w-full">
                <ul className="flex flex-col items-start gap-2.5">
                  <li>
                    <a
                      href="/Services"
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[18px]"
                    >
                      <Heading
                        size="headinglg"
                        as="h5"
                        className="text-[22px] font-bold text-white-a700"
                      >
                        Services
                      </Heading>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-6 flex items-center gap-2.5  ">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Personal Care Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Personal Care
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Companionship Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Companionship
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Medication Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Medication Management
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Live-in Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Live-In Care
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Dementia Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Dementia Care
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Respite Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Respite Care
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Nursing Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Nursing Care
                        </Heading>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="/services">
                      <div className="mt-2.5 flex items-center gap-2.5">
                        <Img
                          src="images/img_arrow_left_blue_600_01.svg"
                          alt="Household Icon"
                          className="h-[10px]"
                        />
                        <Heading
                          as="h6"
                          className="text-[16px] font-semibold text-gray-300 lg:text-[13px]"
                        >
                          Household Support
                        </Heading>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-10 h-px w-full self-stretch bg-blue-600_01" />
          <div className="mt-5 flex items-center justify-between gap-5 self-stretch sm:flex-col">
            <Text
              size="textxs"
              as="p"
              className="text-[14px] font-normal text-gray-300"
            >
              © 2024. All Rights Reserved
            </Text>
            <div className="flex w-[25%] justify-center gap-3.5 sm:w-full ">
              <div className="flex w-[46%] justify-center">
                <a href="/privacypolicy">
                  <div className="flex w-full flex-wrap justify-center gap-3.5">
                    <Heading
                      as="h6"
                      className="text-[16px] font-semibold text-white-a700 lg:text-[13px]"
                    >
                      Privacy Policy
                    </Heading>
                    <Heading
                      as="h6"
                      className="text-[16px] font-semibold text-white-a700 lg:text-[13px]"
                    >
                      |
                    </Heading>
                  </div>
                </a>
              </div>
              <a href="/termsconditions" className="lg:text-[13px]">
                <Heading
                  as="h6"
                  className="text-[16px] font-semibold text-white-a700"
                >
                  Terms & Conditions
                </Heading>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import About from "pages/About";
import Services from "pages/Services";
import Contact from "pages/Contact";
import Register from "pages/Register";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsConditions from "pages/TermsConditions";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home1 /> },
    { path: "*", element: <NotFound /> },
    {
      path: "home",
      element: <Home1 />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "services",
      element: <Services />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "privacypolicy",
      element: <PrivacyPolicy />,
    },
    {
      path: "termsconditions",
      element: <TermsConditions />,
    },
  ]);

  return element;
};

export default ProjectRoutes;

import { Img, Text, Heading } from "../../components";
import React from "react";

export default function AboutSection() {
  return (
    <>
      {/* about section */}
      <div className="self-stretch">
        <div className="flex items-start justify-center bg-white-a700 md:flex-col">
          <div className="mt-[118px] flex w-[34%] flex-col items-start gap-0.5 md:w-full md:px-5">
            <Heading
              size="heading3xl"
              as="h1"
              className="text-[74px] font-extrabold text-blue_gray-900_01 lg:text-[48px] md:text-[48px]"
            >
              About Us
            </Heading>
            <Text
              size="textmd"
              as="p"
              className="w-[92%] text-[18px] font-normal leading-7 text-gray-600_01 lg:w-full lg:text-[15px] md:w-full"
            >
              At CENTRIX CONSULTANCY LTD, we are dedicated to delivering exceptional care tailored to the needs of each
              individual. Our team of compassionate professionals prioritises the well-being of our clients, ensuring
              they receive the highest quality support in a safe and nurturing environment.
            </Text>
          </div>
          <Img
            src="images/img_img_520x924.png"
            alt="Product Image"
            className="h-full w-[48%] self-center object-contain md:w-full mt-2 sm:h-full"
          />
        </div>
      </div>
    </>
  );
}

import { Button, TextArea, Input, Text, Heading, Img } from "../../components";
import React from "react";
import { useContactForm } from "./useContact";
import { TextField } from "@mui/material";
// import { useRegisterForm } from "../path-to-your-hook"; // Update with correct path

export default function ContactSection() {
  const {
    formData,
    handleChange,
    handleSubmit,
    validationErrors,
    isCheckboxSelected,
    handleCheckboxChange,
  } = useContactForm();

  return (
    <>
      {/* contact section */}

      <form
        onSubmit={handleSubmit}
        className="container flex flex-col gap-6 p-8 shadow-lg rounded-lg bg-white"
      >
        <div className="mt-[60px]">
          <div className="flex items-center md:flex-col">
            <div className="flex flex-1 items-center justify-center md:flex-col md:self-stretch md:px-5">
              <div className="bg-blue_gray-900_01 h-[870px] w-[48%]  md:hidden lg:block lg:h-[800px] lg:w-[60%] " />

              <Img
                src="images/img_img_610x610.png"
                alt="Primary Image"
                className="relative ml-[-304px] h-full w-[50%] object-cover md:ml-0 md:w-full mt-2 sm:h-full"
              />
            </div>
            <div className="flex w-[48%] flex-col items-start md:w-full md:px-5">
              <a href="#">
                <Heading
                  size="headingxs"
                  as="h1"
                  className="text-[14px] font-extrabold tracking-[1.40px] text-blue-600_01"
                >
                  CONTACT US
                </Heading>
              </a>
              <Heading
                size="heading2xl"
                as="h2"
                className="mt-1.5 text-[54px] font-bold text-blue_gray-900_01 lg:text-[45px] md:text-[34px] sm:text-[28px]"
              >
                Get In Touch
              </Heading>
              <Text
                size="textmd"
                as="p"
                className="text-[18px] font-normal text-gray-600_01 lg:text-[15px]"
              >
                Reach Out for Exceptional Care Today!
              </Text>
              <div className="mt-[58px] self-stretch">
                <div className="flex flex-col items-start gap-8">
                  <div className="flex gap-[30px] self-stretch md:flex-col">
                    {/* <Input
                      shape="square"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      className="w-[32%] !border-b px-3 md:w-full"
                    />
                    {validationErrors.name && (
                      <span className="text-red-500">
                        {validationErrors.name}
                      </span>
                    )}
                    <Input
                      shape="square"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      className="w-[32%] !border-b px-3 md:w-full"
                    />
                    {validationErrors.email && (
                      <span className="text-red-500">
                        {validationErrors.email}
                      </span>
                    )} */}
                    <TextField
                      variant="standard"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      fullWidth
                      error={!!validationErrors.name}
                      helperText={validationErrors.name}
                    />
                    <TextField
                      variant="standard"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      fullWidth
                      error={!!validationErrors.email}
                      helperText={validationErrors.email}
                    />
                  </div>
                  <div className="flex gap-[30px] self-stretch md:flex-row sm:flex-col">
                    {/* <Input
                    shape="square"
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Subject"
                    className="w-[66%] !border-b px-3"
                  />
                  {validationErrors.phoneNumber && (
                    <span className="text-red-500">
                      {validationErrors.phoneNumber}
                    </span>
                  )} */}
                    <TextField
                      variant="standard"
                      type="text"
                      name="phoneNumber"
                      // label="Your Phone No."
                      placeholder="Your Phone No."
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      fullWidth
                      error={Boolean(validationErrors.phoneNumber)}
                      helperText={validationErrors.phoneNumber || " "}
                    // className="flex-row"

                    />
                    <TextField
                      variant="standard"
                      type="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Subject"
                      fullWidth
                      error={!!validationErrors.subject}
                      helperText={validationErrors.subject}
                      sx={{ mt: '1rem' }}

                    // className="flex-row"

                    />
                  </div>
                  <TextArea
                    shape="square"
                    name="query"
                    value={formData.query}
                    onChange={handleChange}
                    placeholder="Write Message"
                    className="w-[100%] !border-b !border-gray-300 px-3  text-gray-600_01"
                  />
                  {validationErrors.query && (
                    <span className="text-red-500">
                      {validationErrors.query}
                    </span>
                  )}
                  <Button
                    shape="round"
                    className="min-w-[204px] rounded-[30px] px-[34px] font-extrabold tracking-[1.40px] sm:px-4"
                    type="submit"
                  >
                    SEND MESSAGE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

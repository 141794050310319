import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  offerTitle?: React.ReactNode;
  offerDescription?: React.ReactNode;
  stepOneNumber?: React.ReactNode;
  stepOneHeading?: React.ReactNode;
  stepOneDescription?: React.ReactNode;
  stepTwoNumber?: React.ReactNode;
  stepTwoHeading?: React.ReactNode;
  stepTwoDescription?: React.ReactNode;
  stepThreeNumber?: React.ReactNode;
  stepThreeHeading?: React.ReactNode;
  stepThreeDescription?: React.ReactNode;
}

export default function CareServiceOverview({
  offerTitle = "We Offer",
  offerDescription,
  stepOneNumber = "01",
  stepOneHeading = "Round-the-Clock Personal Support",
  stepOneDescription = "Our dedicated caregivers provide full-time assistance, ensuring your loved ones receive the help they need at all hours.",
  stepTwoNumber = "02",
  stepTwoHeading = "Regular Well-Being Check-Ins",
  stepTwoDescription = "We conduct live assessments of residents’ well-being, ensuring their needs are met ",
  stepThreeNumber = "03",
  stepThreeHeading = "Comprehensive Safety Monitoring",
  stepThreeDescription = "Our advanced Carers keep Clients safe, providing peace of mind for families.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex items-center flex-1 md:flex-col`}>
      <Img
        src="images/img_img_980x960.png"
        alt="Main Image"
        className="h-[960px] lg:h-auto w-[50%] object-contain md:w-full"
      />
      <div   className="h-[718px] lg:h-auto w-[50%] object-contain md:w-full">
        <div className="flex flex-1 flex-col items-start self-end bg-indigo-900 py-[151.5px] pl-[89px] pr-1 lg:py-8 lg:pl-8 md:self-stretch md:p-5 sm:p-4">
          <Heading
            size="headingxs"
            as="p"
            className="text-[14px] font-extrabold tracking-[1.40px] text-blue-600_01"
          >
            {offerTitle}
          </Heading>
          <Text
            size="textlg"
            as="p"
            className="mt-2.5 text-[54px] font-light leading-[65px] text-white-a700 lg:text-[45px] md:text-[34px] sm:text-[28px]"
          >
            <span>Quality Care&nbsp;</span>
            <span className="font-normal">
              <>
                & Enhancing
                <br />
              </>
            </span>
            <span className="font-bold">Your Freedom</span>
          </Text>
          <div className="mb-[108px] mt-[34px] flex w-[94%] flex-col gap-[22px] lg:w-full md:w-full">
            <div className="flex flex-col gap-2.5">
              <div className="flex items-start justify-center gap-6 md:flex-col">
                <Heading
                  size="headinglg"
                  as="h5"
                  className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-white-a700 text-center text-[22px] font-bold text-blue-600_01 lg:text-[18px]"
                >
                  {stepOneNumber}
                </Heading>
                <div className="flex flex-1 flex-col items-start self-center md:self-stretch">
                  <Heading
                    size="headingmd"
                    as="h6"
                    className="text-[18px] font-bold text-white-a700 lg:text-[15px]"
                  >
                    {stepOneHeading}
                  </Heading>
                  <Text
                    as="p"
                    className="w-[90%] text-[16px] font-normal leading-[25px] text-gray-300 lg:w-full lg:text-[13px] md:w-full"
                  >
                    {stepOneDescription}
                  </Text>
                </div>
              </div>
              <div className="flex items-start justify-center gap-6 md:flex-col">
                <Heading
                  size="headinglg"
                  as="h5"
                  className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-white-a700 text-center text-[22px] font-bold text-blue-600_01 lg:text-[18px]"
                >
                  {stepTwoNumber}
                </Heading>
                <div className="flex flex-1 flex-col items-start gap-2 md:self-stretch">
                  <Heading
                    size="headingmd"
                    as="h6"
                    className="text-[18px] font-bold text-white-a700 lg:text-[15px]"
                  >
                    {stepTwoHeading}
                  </Heading>
                  <Text
                    as="p"
                    className="text-[16px] font-normal text-gray-300 lg:text-[13px]"
                  >
                    {stepTwoDescription}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex items-start justify-center gap-6 md:flex-col">
              <Heading
                size="headinglg"
                as="h5"
                className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-white-a700 text-center text-[22px] font-bold text-blue-600_01 lg:text-[18px]"
              >
                {stepThreeNumber}
              </Heading>
              <div className="flex flex-1 flex-col items-start gap-2 md:self-stretch">
                <Heading
                  size="headingmd"
                  as="h6"
                  className="text-[18px] font-bold text-white-a700 lg:text-[15px]"
                >
                  {stepThreeHeading}
                </Heading>
                <Text
                  as="p"
                  className="text-[16px] font-normal text-gray-300 lg:text-[13px]"
                >
                  {stepThreeDescription}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CareServicesSection from "./CareServicesSection";
import ContactUsSection from "./ContactUsSection";
import WelcomeSection from "./WelcomeSection";
import React from "react";

export default function Home1Page() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <Header />
        <div className="flex flex-col gap-[130px] lg:gap-[97px] md:gap-[30px] sm:gap-[65px]">
          {/* contact us section */}
          <ContactUsSection />

          {/* welcome section */}
          <WelcomeSection />

          {/* care services section */}
          <CareServicesSection />
        </div>
        <Footer className="mt-[130px]" />
      </div>
    </>
  );
}

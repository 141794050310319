import { Img, Text, Heading } from "../../components";
import UserSupportAndCare from "../../components/UserSupportAndCare";
import React, { Suspense } from "react";

const careFeaturesList = [
  {
    carePlanText: "Personalised Care Plans",
    supportServiceText: "24/7 Support Services",
  },
  {
    carePlanText: "Experienced Caregivers",
    supportServiceText: "Home Safety Assessments",
  },
  {
    carePlanText: "Medication Management",
    supportServiceText: "Companionship Services",
  },
  {
    carePlanText: "Emergency Response Systems",
    supportServiceText: "Rehabilitation Programmes",
  },
  {
    carePlanText: "Social and Recreational Activities",
    supportServiceText: "Regular Family Updates",
  },
];

export default function WelcomeSection() {
  return (
    <>
      {/* welcome section */}
      <div className="relative h-[672px] md:h-[500px] sm:h-auto p-4 md:p-0 lg:h-[550px] md:h-[800px]">
        {/* Image hidden on md screens */}
        <Img
          src="images/img_img_500x774.png"
          alt="Image Three"
          className="absolute right-[16%] top-0 m-auto h-[500px] w-[40%] object-contain lg:right-[8%] md:right-0 md:relative md:h-[300px] md:w-[100%] sm:w-full"
        />
        <div className="left-0 right-0 top-[10%] m-auto flex w-[70%] flex-col items-start gap-3.5 lg:w-[80%] md:w-[90%] sm:top-[5%] sm:w-full md:mt-[10px]" 
        >
          <Text
            size="textlg"
            as="p"
            className="w-[36%] text-[54px] font-light leading-[65px] text-blue_gray-900_01 lg:text-[45px] lg:leading-[55px] md:w-[60%] md:text-[34px] md:leading-[45px] sm:w-full sm:px-5 sm:text-[28px] sm:leading-[38px]"
          >
            <span>Your Well-Being is&nbsp;</span>
            <span className="font-bold">Our Commitment</span>
          </Text>
          <Text
            size="textmd"
            as="p"
            className="w-[36%] text-[18px] font-normal leading-7 text-gray-600_01 lg:text-[16px] lg:leading-6 md:w-[60%] md:text-[15px] sm:w-full sm:px-5 sm:text-[14px]"
          >
            At our organisation, we understand that compassionate support and
            security are essential for our clients. We strive to create a
            nurturing environment that enhances the quality of life for
            individuals in need of care.
          </Text>
        </div>
        <div className="absolute bottom-0 left-[16%] flex w-[38%] lg:w-[50%] flex-col gap-4 bg-indigo-900 p-3 md:relative md:left-0 md:w-[100%] md:mt-[20px] sm:w-full sm:p-4">
          <Suspense fallback={<div>Loading feed...</div>}>
            {careFeaturesList.map((d, index) => (
              <UserSupportAndCare {...d} key={"itemList" + index} />
            ))}
          </Suspense>
        </div>
        {/* Image hidden on sm screens */}
        <Img
          src="images/img_mask_group.png"
          alt="Masked Image"
          className="absolute bottom-[18%] right-[4%] m-auto h-[354px] w-[24%] object-contain lg:bottom-[10%] lg:right-[2%] lg:h-[300px] lg:w-[20%] md:relative md:h-[250px] md:w-[40%] sm:h-[200px] sm:w-full md:hidden"
        />
      </div>
    </>
  );
}

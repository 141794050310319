import axios from "axios";
import { CONTACT_US_API_HEADER_KEY, CONTACT_US_API_URL } from "config";
import { useSnackbar } from "notistack";
import { useState } from "react";

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  // jobTitle: string;
}

interface ValidationErrors {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  // jobTitle?: string;
}

export const useRegisterForm = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    // jobTitle: "",
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: string }
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = () => {
    setIsCheckboxSelected((prev) => !prev);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
    }));
  };

  const validateForm = () => {
    const errors: ValidationErrors = {};
    if (!formData.firstName) errors.firstName = "First name is required";
    if (!formData.lastName) errors.lastName = "Last name is required";
    if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required";
    if (!formData.email) errors.email = "Email is required";
    // if (!formData.jobTitle) errors.jobTitle = "Job title is required";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitForm = async () => {
    if (!validateForm()) return;

    try {
      await axios.post(
        "/signup-leads",
        { ...formData, phoneNumber: `+${formData?.phoneNumber}`},
        {
          headers: {
            "x-api-key": CONTACT_US_API_HEADER_KEY,
          },
          baseURL: CONTACT_US_API_URL,
        }
      );

      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        // jobTitle: "",
      });
      setValidationErrors({});
      setIsCheckboxSelected(false);
      enqueueSnackbar(
        "Thank you for reaching out! We will get back to you shortly.",
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar("Error submitting form. Please try again.", {
        variant: "error",
      });
      console.error("Error submitting form:", error);
    }
  };

  return {
    formData,
    handleChange,
    submitForm,
    validationErrors,
    handleCheckboxChange,
    isCheckboxSelected,
  };
};

import { Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  carePlanText?: React.ReactNode;
  supportServiceText?: React.ReactNode;
}

export default function UserSupportAndCare({
  carePlanText = "Personalised Care Plans",
  supportServiceText = "24/7 Support Services",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex justify-between`}>
      <div className="flex gap-[13px] lg:w-[55%]">
        <Img src="images/img_check_1.svg" alt="Check Image" className="h-[18px]" />
        <Heading as="h6" className="text-[16px] font-semibold text-white-a700 sm:text-[13px]">
          {carePlanText}
        </Heading>
      </div>
      <div className="flex  gap-[11px]  md:px-5 w-[45%]">
        <Img src="images/img_check_1.svg" alt="Support Image" className="h-[16px]" />
        <Heading as="h6" className="text-[16px] font-semibold text-white-a700 sm:text-[13px]">
          {supportServiceText}
        </Heading>
      </div>
    </div>
  );
}

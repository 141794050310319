import { Img, Heading } from "../../components";
import ServiceProfile from "../../components/ServiceProfile";
import React, { Suspense } from "react";

const servicesGrid = [
  {
    serviceImage: "images/img_care_1.svg",
    serviceHeading: "Personal Care Assistance",
    serviceDescription:
      "Our dedicated team provides compassionate support for daily living activities, including bathing, dressing, and grooming.",
  },
  {
    serviceImage: "images/img_health_insurance.svg",
    serviceHeading: "Companionship Services",
    serviceDescription:
      "We offer friendly companionship to enhance emotional well-being, engaging clients in meaningful conversations and activities.",
  },
  {
    serviceImage: "images/img_heart_beat_1.svg",
    serviceHeading: "Medication Management",
    serviceDescription: "Our professionals ensure that medications are taken correctly and on time. ",
  },
  {
    serviceImage: "images/img_healthcare_1.svg",
    serviceHeading: "Respite Care",
    serviceDescription: "We provide temporary relief for family caregivers through our respite care services.",
  },
];

export default function CareServicesSection() {
  return (
    <>
      {/* care services section */}
      <div className="relative h-[1140px]">
        <div className="absolute left-0 right-0 top-0 m-auto flex flex-1 justify-center bg-indigo-900 py-[130px] lg:py-8 md:py-5 sm:py-4">
          <div className="container-xs flex justify-center lg:px-5 md:px-5">
            <div className="flex w-full flex-col items-start">
              <Heading
                size="headingxs"
                as="h2"
                className="text-[14px] font-extrabold tracking-[1.40px] text-blue-600_01"
              >
                360° CARE SERVICES
              </Heading>
              <Heading
                size="heading2xl"
                as="h3"
                className="mt-3.5 text-[54px] font-bold leading-[65px] text-white-a700 lg:w-full lg:text-[40px] md:w-full md:text-[34px] sm:text-[28px]"
              >
                <span>Compassionate Care,&nbsp;</span>
                <span className="font-normal">Every Step of the Way.</span>
              </Heading>
              <div className="mr-[670px] mt-12 grid grid-cols-2 gap-12 gap-y-12 self-stretch lg:grid-cols-2 md:mr-0 md:grid-cols-1">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {servicesGrid.map((d, index) => (
                    <ServiceProfile {...d} key={"servicesGrid1" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
        </div>
        <Img
          src="images/img_img_1010x934.png"
          alt="Section Image"
          className="absolute bottom-0 right-px m-auto h-[1010px] w-[38%] lg:w-[48%] object-contain md:hidden"
        />
      </div>
    </>
  );
}

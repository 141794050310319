import { Text } from "../../components";
import React from "react";

export default function PrivacyPolicySection1() {
  return (
    <>
      {/* privacy policy section */}
      <div className="flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <Text
            size="textxs"
            as="p"
            className="w-full font-manrope text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]"
          >
            <span className="text-[30px] font-semibold text-blue_gray-900">
              <>
                {/* Privacy Policy */}
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Introduction
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;At CENTRIX CONSULTANCY LTD (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;), we are dedicated to
                safeguarding the privacy of our users. This policy explains how we collect, use, store, and protect
                personal data in line with the UK GDPR and the Data Protection Act 2018. By using our website and
                services, you agree to the practices set out in this policy.
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Data Collection
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;We may collect and process the following types of personal data:
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Personal Information</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                Includes names, contact details (such as phone numbers and email addresses), and postal addresses.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Health Information</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                When relevant to care provision, we may collect information on health conditions, treatment history, and
                care needs.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Technical Data</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;Such as IP addresses, browser types, and cookie data collected via our website to enhance user
                experience.
                <br /> We gather data directly through forms submitted on our website, phone calls, email
                correspondence, and cookies.
                <br />
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Use of Data
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;Personal data is used for the following purposes:
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Service Provision</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;To deliver requested services, including tailored care plans and support.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Service Improvement</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;To enhance our website, services, and user experience.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Marketing and Communication</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;With your consent, we may use your contact details to send updates and information about our
                services.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Third-Party Sharing</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;We may share data with trusted third parties for specific purposes, including healthcare providers
                involved in your care, payment processors, and legal authorities as required by law.
                <br />
                We do not sell personal data to third parties. Any data sharing is limited to fulfilling our service
                obligations or meeting legal requirements.
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Data Security
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;We implement rigorous security measures to protect personal data from unauthorised access,
                alteration, or disclosure, including:
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Data Encryption</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                Sensitive data is encrypted both during transmission and at rest.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Access Controls</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                Access to personal data is restricted to authorised staff members who require it to perform their
                duties.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Regular Security Audits</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                We conduct audits to keep our data security protocols current and effective.
                <br /> In the event of a data breach, we will notify affected individuals and relevant authorities
                promptly, as required by law.
                <br />
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                User Rights
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;Under the UK GDPR, users have certain rights concerning their personal data, including:
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Access</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;The right to request access to your data.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Correction</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                The right to request the correction of inaccurate or incomplete information.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Deletion</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;The right to request the deletion of your data under certain conditions.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Data Portability</span>
            <span className="text-blue_gray-900_02">:&nbsp;</span>
            <span className="text-gray-600">
              <>
                The right to request transfer of your data to another service provider.
                <br /> To exercise these rights, please contact us using the details below. We will respond to all
                requests in accordance with applicable laws.
                <br />
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Cookies
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;Our website uses cookies to improve user experience and analyse site traffic. Types of cookies
                include:
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Necessary Cookies</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;Essential for website functionality.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Analytics Cookies</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;Help us understand user interactions with our site.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">Preference Cookies</span>
            <span className="text-blue_gray-900_02">:</span>
            <span className="text-gray-600">
              <>
                &nbsp;Store user preferences for a personalised experience.
                <br /> You can manage cookie settings via your browser or adjust preferences when prompted on our site.
                <br />
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Changes to the Policy
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;We may update this Privacy Policy to reflect changes in our practices or applicable legal
                requirements. Updates will be posted on this page, and significant changes will be communicated directly
                to users when required.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                <br />
                Contact Information
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;For any questions or concerns about your data or this policy, please contact us at:
                <br /> CENTRIX CONSULTANCY LTD
                <br /> Address: 1 Ben Vorlich Place, Glasgow, Scotland, G53 7WX
                <br /> Phone: 0141 363 0407
                <br /> Email: info@centrixconsultancy.co.uk
                <br/>
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_02">
              <>
                Compliance and Legal Advice
                <br />
              </>
            </span>
            <span className="text-gray-600">
              &nbsp;This policy is designed to comply with the UK GDPR and the Data Protection Act 2018. For further
              guidance or to ensure full compliance with all relevant laws, consulting a legal professional is
              recommended.
            </span>
          </Text>
        </div>
      </div>
    </>
  );
}

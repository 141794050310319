import { Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  carePlansText?: React.ReactNode;
  supportServicesText?: React.ReactNode;
  safetyText?: React.ReactNode;
  careSchedulingText?: React.ReactNode;
  wellbeingFocusText?: React.ReactNode;
}

export default function ServiceFeatures({
  carePlansText = "Care Plans",
  supportServicesText = "24/7 Support Services",
  safetyText = "Safety Monitoring",
  careSchedulingText = "Care Scheduling",
  wellbeingFocusText = "Well-Being Focus",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center sm:w-full gap-2.5`}
    >
      <div className="flex items-center gap-[11px] self-stretch">
        <Img
          src="images/img_check_1.svg"
          alt="Careplansimage"
          className="h-[16px]"
        />
        <Heading
          as="h6"
          className="text-[16px] font-semibold text-blue_gray-900_01"
        >
          {carePlansText}
        </Heading>
      </div>
      <div className="flex items-center  gap-2.5 self-stretch">
        <Img
          src="images/img_check_1.svg"
          alt="Supportimage"
          className="h-[16px]"
        />
        <Heading
          as="h6"
          className="text-[16px] font-semibold text-blue_gray-900_01"
        >
          {supportServicesText}
        </Heading>
      </div>
      <div className="flex items-center gap-[11px] self-stretch">
        <Img
          src="images/img_check_1.svg"
          alt="Safetyimage"
          className="h-[16px]"
        />
        <Heading
          as="h6"
          className="text-[16px] font-semibold text-blue_gray-900_01"
        >
          {safetyText}
        </Heading>
      </div>
      <div className="flex items-center gap-[11px] self-stretch">
        <Img
          src="images/img_check_1.svg"
          alt="Schedulingimage"
          className="h-[16px]"
        />
        <Heading
          as="h6"
          className="text-[16px] font-semibold text-blue_gray-900_01"
        >
          {careSchedulingText}
        </Heading>
      </div>
      <div className="flex items-center gap-[11px] self-stretch">
        <Img
          src="images/img_check_1.svg"
          alt="Wellbeingimage"
          className="h-[16px]"
        />
        <Heading
          as="h6"
          className="text-[16px] font-semibold text-blue_gray-900_01"
        >
          {wellbeingFocusText}
        </Heading>
      </div>
    </div>
  );
}

import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  personalCareImage?: string;
  personalCareHeading?: React.ReactNode;
  personalCareDescription?: React.ReactNode;
  companionshipImage?: string;
  companionshipHeading?: React.ReactNode;
  companionshipDescription?: React.ReactNode;
  medicationManagementImage?: string;
  medicationManagementHeading?: React.ReactNode;
  medicationManagementDescription?: React.ReactNode;
  liveInCareImage?: string;
  liveInCareHeading?: React.ReactNode;
  liveInCareDescription?: React.ReactNode;
}

export default function CareServiceList({
  personalCareImage = "images/img_care_1.svg",
  personalCareHeading = "Personal Care",
  personalCareDescription = "We provide tailored personal assistance with daily activities, including bathing, grooming, and mobility support, ensuring dignity and comfort.",
  companionshipImage = "images/img_collaboration_1.svg",
  companionshipHeading = "Companionship",
  companionshipDescription = "Our friendly caregivers offer companionship to combat loneliness, providing meaningful interactions and activities that promote emotional well-being.",
  medicationManagementImage = "images/img_health_insurance.svg",
  medicationManagementHeading = "Medication Management",
  medicationManagementDescription = "We assist clients in managing their medications effectively, ensuring they adhere to prescribed schedules and stay safe.",
  liveInCareImage = "images/img_cardiogram_1.svg",
  liveInCareHeading = "Live-In Care",
  liveInCareDescription = "Our live-in care services provide 24/7 support, allowing clients to remain in their own homes while receiving continuous assistance from dedicated caregivers.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex items-center gap-1 flex-1`}>
      <div className="flex w-full flex-col gap-5">
        <Img src={personalCareImage} alt="Care Image" className="h-[54px] w-[54px]" />
        <div className="flex flex-col items-start">
          <Heading size="headinglg" as="h5" className="text-[22px] font-bold text-blue_gray-900_01">
            {personalCareHeading}
          </Heading>
          <Text as="p" className="mb-[38px] w-[94%] text-[16px] font-normal leading-[25px] text-gray-600_01 md:w-full">
            {personalCareDescription}
          </Text>
        </div>
      </div>
      <div className="flex w-full flex-col gap-5">
        <Img src={companionshipImage} alt="Companionship Image" className="h-[54px] w-[54px]" />
        <div className="flex flex-col items-start">
          <Heading size="headinglg" as="h5" className="text-[22px] font-bold text-blue_gray-900_01">
            {companionshipHeading}
          </Heading>
          <Text as="p" className="mb-[38px] w-full text-[16px] font-normal leading-[25px] text-gray-600_01">
            {companionshipDescription}
          </Text>
        </div>
      </div>
      <div className="flex w-full flex-col gap-5">
        <Img src={medicationManagementImage} alt="Medication Image" className="h-[54px] w-[54px]" />
        <div className="flex flex-col items-start">
          <Heading size="headinglg" as="h5" className="text-[22px] font-bold text-blue_gray-900_01">
            {medicationManagementHeading}
          </Heading>
          <Text as="p" className="mb-[38px] w-[90%] text-[16px] font-normal leading-[25px] text-gray-600_01 md:w-full">
            {medicationManagementDescription}
          </Text>
        </div>
      </div>
      <div className="flex w-full flex-col gap-5">
        <Img src={liveInCareImage} alt="Live-in Image" className="h-[54px] w-[54px]" />
        <div className="flex flex-col items-start">
          <Heading size="headinglg" as="h5" className="text-[22px] font-bold text-blue_gray-900_01">
            {liveInCareHeading}
          </Heading>
          <Text as="p" className="mb-[38px] w-full text-[16px] font-normal leading-[25px] text-gray-600_01">
            {liveInCareDescription}
          </Text>
        </div>
      </div>
    </div>
  );
}

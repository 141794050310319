import { Button, Text, Heading, Img } from "../../components";
import React from "react";

export default function ContactUsSection() {
  return (
    <>
      {/* contact us section */}
      <div>
        <div className="flex items-center md:flex-col">
          <div className="flex flex-1 flex-col items-start md:self-stretch">
            <Img
              src="images/img_img.png"
              alt="Primary Image"
              className="h-auto w-[90%] object-contain md:w-full"
              style={{background:'blue'}}
            />
            <a href="/contact" target="_blank" className="w-full">
              <div className="flex w-[90%] items-center justify-between gap-5 bg-indigo-900 md:w-full">
                <Heading
                  size="headinglg"
                  as="h1"
                  className="ml-[50px] text-[22px] font-bold text-white-a700 lg:text-[18px]"
                >
                  Reach Out for Personalised Care. Contact Us!
                </Heading>
                <Button
                  size="sm"
                  shape="square"
                  className="w-[130px] px-11 md:px-5 sm:px-4"
                >
                  <Img src="images/img_arrow_left.svg" />
                </Button>
              </div>
            </a>
          </div>
          <div className="flex w-[44%] flex-col items-start md:w-full md:p-5 ">
            <Heading
              size="headingxs"
              as="h2"
              className="text-[14px] font-extrabold tracking-[1.40px] text-blue-600_01"
            >
              WELCOME TO CENTRIX CONSULTANCY LTD
            </Heading>
            <Text
              size="textxl"
              as="p"
              className="mt-3 text-[60px] font-normal leading-[90px] text-blue_gray-900_01 lg:w-full lg:text-[34px] md:w-full md:text-[48px] sm:w-full sm:text-[34px]"
            >
              <span>Where care meets&nbsp;</span>
              <span className="font-bold">compassion</span>
            </Text>
            <Text
              size="textmd"
              as="p"
              className="w-[78%] text-[18px] font-normal leading-7 text-gray-600_01 lg:w-full lg:text-[15px] md:w-full"
            >
              Discover exceptional, personalised care services across the UK.
              Our experienced team is dedicated to supporting the well-being,
              independence, and comfort of each individual.
            </Text>
            <a href="/register" >
              <Button
                shape="round"
                className="mt-[26px] min-w-[186px] rounded-[30px] px-[34px] font-extrabold tracking-[1.40px] sm:px-4"
              >
                GET STARTED
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CompassionateCareSection from "./CompassionateCareSection";
import ServicesSection from "./ServicesSection";
import ServicesSection1 from "./ServicesSection1";
import React from "react";

export default function ServicesPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <Header />
        <div className="flex flex-col gap-[130px] lg:gap-[97px] md:gap-[97px] sm:gap-[65px]">
          {/* services section */}
          <ServicesSection />

          {/* compassionate care section */}
          <CompassionateCareSection />

          {/* services section */}
          <ServicesSection1 />
          
        </div>
        <Footer className="mt-[130px]" />
      </div>
    </>
  );
}

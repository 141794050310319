import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  carePlanImage?: string;
  carePlanHeading?: React.ReactNode;
  carePlanDescription?: React.ReactNode;
}

export default function CarePlansOverview({
  carePlanImage = "images/img_planning_2_1.svg",
  carePlanHeading = "Care Plans",
  carePlanDescription = "care plans based on each client’s unique needs, preferences, and circumstances.",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-center gap-5 flex-1`}
    >
      <Img
        src={carePlanImage}
        alt="Security Image"
        className="h-[50px] w-[50px]"
      />
      <div className="flex flex-1 flex-col items-start">
        <Heading
          size="headingxs"
          as="h6"
          className="text-[18px] font-semibold text-white-a700"
        >
          {carePlanHeading}
        </Heading>
        <Text as="p" className="text-[16px] font-normal text-gray-300">
          {carePlanDescription}
        </Text>
      </div>
    </div>
  );
}

import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  serviceImage?: string;
  serviceHeading?: React.ReactNode;
  serviceDescription?: React.ReactNode;
}

export default function ServiceProfile({
  serviceImage = "images/img_care_1.svg",
  serviceHeading = "Personal Care Assistance",
  serviceDescription = "Our dedicated team provides compassionate support for daily living activities, including bathing, dressing, and grooming.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start w-full gap-5`}>
      <Img src={serviceImage} alt="Service Image" className="h-[54px] w-[54px]" />
      <div className="flex flex-col items-start self-stretch md:items-start">
        <Heading size="headinglg" as="h5" className="text-[22px] font-bold text-white-a700">
          {serviceHeading}
        </Heading>
        <Text as="p" className="mb-[38px] w-full text-[16px] font-normal leading-[25px] text-gray-300">
          {serviceDescription}
        </Text>
      </div>
    </div>
  );
}

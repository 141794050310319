import Footer from "../../components/Footer";
import Header from "../../components/Header";
import RegistrationFormSection from "./RegistrationFormSection";
import React from "react";

export default function RegisterPage() {
  return (
    <>
      <div className="flex w-full flex-col gap-[130px] bg-white-a700 lg:gap-[97px] md:gap-[97px] sm:gap-[65px]">
        <Header className="py-5" />

        {/* registration form section */}
        <RegistrationFormSection />
        <Footer />
      </div>
    </>
  );
}

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ContactSection from "./ContactSection";
import React from "react";

export default function ContactPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <Header />

        {/* contact section */}
        <ContactSection />
        <Footer className="mt-[130px]" />
      </div>
    </>
  );
}

import { useLocation } from "react-router-dom";
import { Button, Heading, Img } from "./..";
import React, { useState } from "react";

interface Props {
  className?: string;
}

const navLinks = [
  { path: "/home", label: "Home" },
  { path: "/about", label: "About" },
  { path: "/services", label: "Services" },
  { path: "/contact", label: "Contact" },
];

export default function Header({ ...props }: Props) {
  const location = useLocation();
  console.log("Current path:", location.pathname); // Debugging line
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };
  return (
    <header
      {...props}
      className={`${props.className} flex justify-center items-start border-gray-300 border-b border-solid bg-white-a700`}
    >
      <div className="container-xs mb-5 flex justify-center px-4 lg:px-5 md:px-5">
        <div className="flex w-full flex-col items-start gap-4">
          <div className="mx-auto lg:ml-[350px] h-[3px] w-[6%] bg-blue-600_01 md:ml-0" />
          <div className="flex items-center justify-between gap-5 w-full md:flex-col md:gap-4">
            <Img
              src="images/img_header_logo.svg"
              alt="Header Logo"
              className="h-[32px] w-[164px] object-contain md:w-[120px] md:h-[24px]"
            />
            <ul className="flex flex-wrap gap-[100px] lg:gap-5 md:gap-5 md:order-2 md:justify-center">
              {navLinks.map((link) => (
                <li key={link.path}>
                  <a href={link.path} className="cursor-pointer lg:text-[15px]">
                    <Heading
                      size="headingmd"
                      as="h6"
                      className={`text-[18px] font-bold  ${
                        location.pathname === link.path
                          ? "text-blue-600_01"
                          : "text-blue_gray-900_01 hover:text-blue-600_01"
                      }`}
                    >
                      {link.label}
                    </Heading>
                  </a>
                </li>
              ))}
            </ul>
            <a href="/register" className="md:order-1">
              <Button
                shape="round"
                className="min-w-[186px] rounded-[30px] px-[34px] font-extrabold tracking-[1.40px] sm:px-4 md:min-w-[140px] md:px-3"
              >
                GET STARTED
              </Button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  safetyImage?: string;
  headingText?: React.ReactNode;
  safetyMeasuresText?: React.ReactNode;
}

export default function SafetyProtocolSection({
  safetyImage = "images/img_security_1.svg",
  headingText = "Safety Protocols",
  safetyMeasuresText = "Safety measures to protect our clients at all times.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex items-center gap-5 flex-1`}>
      <Img src={safetyImage} alt="Security Image" className="h-[50px] w-[50px]" />
      <div className="flex flex-1 flex-col items-start">
        <Heading size="headingxs" as="h6" className="text-[18px] font-semibold text-white-a700">
          {headingText}
        </Heading>
        <Text as="p" className="text-[16px] font-normal text-gray-300">
          {safetyMeasuresText}
        </Text>
      </div>
    </div>
  );
}

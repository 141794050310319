import { Text, Heading } from "./..";
import React from "react";

interface Props {
  className?: string;
  storyNumber?: React.ReactNode;
  sectionHeading?: React.ReactNode;
  storyDescription?: React.ReactNode;
}

export default function OurStoriesSection({
  storyNumber = "01",
  sectionHeading = "Our Stories",
  storyDescription = "Our journey began with a simple belief: that everyone deserves access to compassionate and professional care",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center justify-center w-[32%] md:w-full gap-5 p-[34px] sm:p-4 bg-gray-50`}
    >
      <div className="mt-1 flex flex-wrap items-center gap-5 self-stretch">
        <Heading
          size="headinglg"
          as="h5"
          className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-blue-600_01 text-center text-[22px] font-bold text-white-a700"
        >
          {storyNumber}
        </Heading>
        <Heading size="headingxl" as="h3" className="text-[28px] font-bold text-blue_gray-900_01">
          {sectionHeading}
        </Heading>
      </div>
      <Text as="p" className="w-[96%] text-[16px] font-normal leading-[25px] text-gray-600_01">
        {storyDescription}
      </Text>
    </div>
  );
}

import axios from "axios";
import { CONTACT_US_API_HEADER_KEY, CONTACT_US_API_URL } from "config";
import { useSnackbar } from "notistack";
import { useState } from "react";

interface FormData {
  name: string;
  email: string;
  subject: string;
  query: string;
  phoneNumber: string;
}

interface ValidationErrors {
  name?: string;
  email?: string;
  subject?: string;
  query?: string;
  phoneNumber?: string;
}

export const useContactForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    query: "",
  });
  const formDataDetails = {
    name: formData.name,
    email: formData.email,
    query: formData.query,
    phoneNumber: formData.phoneNumber,
  };

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: string }
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = () => {
    setIsCheckboxSelected((prev) => !prev);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
    }));
  };

  const validateForm = () => {
    const errors: ValidationErrors = {};
    if (!formData.name) errors.name = "your name is required";
    if (!formData.email) errors.email = "Your email is required";
    // if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.query) errors.query = "Message is required";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitForm = async () => {
    if (!validateForm()) return;

    try {
      await axios.post("/enquiries", formDataDetails, {
        headers: {
          "x-api-key": CONTACT_US_API_HEADER_KEY,
        },
        baseURL: CONTACT_US_API_URL,
      });

      setFormData({
        name: "",
        email: "",
        subject: "",
        phoneNumber: "",
        query: "",
      });
      setValidationErrors({});
      setIsCheckboxSelected(false);
      enqueueSnackbar(
        "Thank you for reaching out! We will get back to you shortly.",
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar("Error submitting form. Please try again.", {
        variant: "error",
      });
      console.error("Error submitting form:", error);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitForm();
  };
  return {
    formData,
    handleChange,
    handleSubmit,
    validationErrors,
    handleCheckboxChange,
    isCheckboxSelected,
  };
};

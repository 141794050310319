import React from "react";

const sizes = {
  textxs: "text-[16px] font-normal not-italic lg:text-[13px]",
  texts: "text-[16px] font-normal not-italic lg:text-[13px]",
  textmd: "text-[18px] font-normal not-italic lg:text-[15px]",
  textlg: "text-[54px] font-light lg:text-[45px] md:text-[46px] sm:text-[40px]",
  textxl: "text-[74px] font-normal not-italic lg:text-[74px] md:text-[48px]",
};

export type TextProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "texts",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component className={`text-gray-600_01 font-manrope ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };

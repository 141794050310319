import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';



function App() {
  return (
    // <Router>

    //   <Routes />
    // </Router>
    <Router>
      <SnackbarProvider maxSnack={3}>
        <Routes />
      </SnackbarProvider>
    </Router>
  );
}

export default App;

import React from "react";

const sizes = {
  headingxs: "text-[18px] font-semibold lg:text-[15px]",
  headings: "text-[16px] font-semibold lg:text-[13px]",
  headingmd: "text-[18px] font-bold lg:text-[15px]",
  headinglg: "text-[22px] font-bold lg:text-[18px]",
  headingxl: "text-[28px] font-bold lg:text-[23px] md:text-[26px] sm:text-[24px]",
  heading2xl: "text-[54px] font-bold lg:text-[45px] md:text-[46px] sm:text-[40px]",
  heading3xl: "text-[74px] font-extrabold lg:text-[74px] md:text-[48px]",
};

export type HeadingProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  size = "headings",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component className={`text-white-a700 font-manrope ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };

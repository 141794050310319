import { Heading, Img, Text } from "../../components";
import CarePlansOverview from "../../components/CarePlansOverview";
import ProfessionalProfile from "../../components/ProfessionalProfile";
import SafetyProtocolSection from "../../components/SafetyProtocolSection";
import React, { Suspense } from "react";

const professionalTeamList = [
  {
    nurseImage: "images/img_nurse_1_1.svg",
    headingText: "Qualified Professionals",
    descriptionText: "Fully trained and experienced in delivering high-quality care services.",
  },
  {
    nurseImage: "images/img_nurse_1.svg",
    headingText: "Compassionate Support",
    descriptionText:
      "We prioritise empathy and respect in all interactions, ensuring clients feel valued and understood.",
  },
  {
    nurseImage: "images/img_presentation_1.svg",
    headingText: "Continuous Training",
    descriptionText: "Regular training to stay updated on best practices",
  },
];
const safetyProtocolsList = [
  {
    safetyImage: "images/img_security_1.svg",
    headingText: "Safety Protocols",
    safetyMeasuresText: "Safety measures to protect our clients at all times.",
  },
  {
    safetyImage: "images/img_settings.png",
    headingText: "24/7 Availability",
    safetyMeasuresText: "Our services are accessible around the clock, providing peace of mind for families.",
  },
  {
    safetyImage: "images/img_security_2.svg",
    headingText: "Well-Being Monitoring",
    safetyMeasuresText: "Regular check-ins and assessments are conducted to enhance our clients' overall health and happiness.",
  },
];
const carePlanDetails = [
  {
    carePlanImage: "images/img_planning_2_1.svg",
    carePlanHeading: "Care Plans",
    carePlanDescription: "care plans based on each client’s unique needs, preferences, and circumstances.",
  },
  {
    carePlanImage: "images/img_check_list_1.svg",
    carePlanHeading: "Regular Assessments",
    carePlanDescription: "Ensure that care remains aligned with changing needs and goals.",
  },
  {
    carePlanImage: "images/img_planning_3_1.svg",
    carePlanHeading: "Family Involvement",
    carePlanDescription: "Actively engage families, fostering collaboration and support.",
  },
];

export default function WhyChooseUsSection() {
  return (
    <>
      {/* why choose us section */}
      <div className="self-stretch container-xs">
        <div className="flex justify-center bg-indigo-900 py-[92px] lg:py-8 md:py-5 sm:py-4">
          <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
            <Text
              size="textlg"
              as="p"
              className="text-[54px] font-light text-white-a700 lg:text-[45px] md:text-[34px] sm:text-[28px]"
            >
              Why Choose Us?
            </Text>
          </div>
        </div>
        <div className="mx-auto flex md:mx-0 md:flex-col">
          <Img
            src="images/img_img_700x644.png"
            alt="Featured Image"
            className="h-full w-[50%] object-contain md:w-full"
          />
          <div className="flex flex-1 flex-col items-end gap-6 bg-indigo-900 px-10 py-2 lg:py-8 md:self-stretch md:p-5 sm:px-5 sm:py-4">
            <Heading
              size="heading2xl"
              as="h2"
              className="w-[94%] text-[54px] font-bold leading-[65px] text-white-a700 lg:w-full lg:text-[45px] md:w-full md:text-[34px] sm:text-[28px]"
            >
              Personalised Care{" "}
            </Heading>
            <div className="mb-3 self-stretch">
              <div className="ml-[30px] flex flex-col gap-[34px] md:ml-0">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {carePlanDetails.map((d, index) => (
                    <CarePlansOverview {...d} key={"carePlansList" + index} className="h-[78px] md:w-full" />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto flex md:mx-0 md:flex-col">
          <div className="flex w-full flex-col items-center justify-center gap-4 bg-indigo-900 px-10 py-[156px] lg:py-8 md:p-5 sm:px-5 sm:py-4">
            <Heading
              size="heading2xl"
              as="h3"
              className="text-[54px] font-bold text-white-a700 lg:text-[45px] md:text-[34px] sm:text-[28px]"
            >
              Experienced Team
            </Heading>
            <div className="mb-1.5 ml-7 flex flex-col gap-[34px] self-stretch md:ml-0">
              <Suspense fallback={<div>Loading feed...</div>}>
                {professionalTeamList.map((d, index) => (
                  <ProfessionalProfile {...d} key={"securityList" + index} className="mr-2 md:mr-0 sm:flex-col" />
                ))}
              </Suspense>
            </div>
          </div>
          <div className="w-full bg-blue_gray-100 md:px-5">
            <Img
              src="images/img_img_1.png"
              alt="Security Image"
              className="h-full w-full object-cover lg:h-auto md:h-auto"
            />
          </div>
        </div>
        <div className="mx-auto flex md:mx-0 md:flex-col">
          <Img src="images/img_img_2.png" alt="Featured Image" className="h-full w-[50%] object-contain md:w-full" />
          <div className="flex flex-1 flex-col items-end gap-6 bg-indigo-900 px-10 py-[104px] lg:py-8 md:self-stretch md:p-5 sm:px-5 sm:py-4">
            <Heading
              size="heading2xl"
              as="h4"
              className="w-[94%] text-[54px] font-bold leading-[65px] text-white-a700 lg:w-full lg:text-[45px] md:w-full md:text-[34px] sm:text-[28px]"
            >
              Safety and Well-Being
            </Heading>
            <div className="mb-[60px] ml-[30px] flex flex-col gap-[34px] self-stretch md:ml-0">
              <Suspense fallback={<div>Loading feed...</div>}>
                {safetyProtocolsList.map((d, index) => (
                  <SafetyProtocolSection {...d} key={"wellBeingList" + index} />
                ))}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

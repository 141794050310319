import { Img, Text, Heading } from "../../components";
import React from "react";

export default function ServicesSection() {
  return (
    <>
      {/* services section */}
      <div>
        <div className="flex items-start justify-end bg-white-a700 md:flex-col">
          <div className="mt-[118px] md:mt-[40px] flex w-[34%] lg:w-[45%] flex-col items-start gap-0.5 md:w-full md:px-5">
            <Heading
              size="heading3xl"
              as="h1"
              className="text-[74px] font-extrabold text-blue_gray-900_01 lg:text-[48px] md:text-[48px]"
            >
              Services
            </Heading>
            <Text
              size="textmd"
              as="p"
              className="w-[92%] text-[18px] font-normal leading-7 text-gray-600_01 lg:w-full lg:text-[15px] md:w-full"
            >
              We offer a wide range of personalised care services designed to enhance the quality of life for
              individuals. From personal care to specialised support, our dedicated team ensures compassionate
              assistance in the comfort of home.
            </Text>
          </div>
          <Img
            src="images/img_img_3.png"
            alt="Featured Image"
            className="h-[520px] w-[48%] self-center object-contain md:w-full"
          />
        </div>
      </div>
    </>
  );
}

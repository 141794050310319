import CareServiceOverview from "../../components/CareServiceOverview";
import React from "react";
import ServicesSection2 from "./ServicesSection2";

export default function ServicesSection1() {
  return (
    <>
      {/* services section */}
      <div className="flex flex-col">
        <CareServiceOverview
          stepOneHeading="Round-the-Clock Personal Support"
          stepOneDescription="Our dedicated caregivers provide full-time assistance, ensuring your loved ones receive the help they need at all hours."
          className="md:px-5 sm:flex-col"
        />

        <ServicesSection2
          stepOneHeading="24/7 Tailored Care "
          stepOneDescription="Our experienced team offers personalised support around the clock, ensuring your loved ones receive the care they need whenever they need it."
          className="mr-1.5 md:mr-0 md:px-5 sm:flex-col flex flex-row-reverse "
        
        />
      </div>
    </>
  );
}

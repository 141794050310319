import { Button, Text, Img } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import OurStoriesSection from "../../components/OurStoriesSection";
import ServiceFeatures from "../../components/ServiceFeatures";
import AboutSection from "./AboutSection";
import WhyChooseUsSection from "./WhyChooseUsSection";
import React, { Suspense } from "react";

const aboutUsStories = [
  {
    storyNumber: "01",
    sectionHeading: "Our Stories",
    storyDescription:
      "Our journey began with a simple belief: that everyone deserves access to compassionate and professional care",
  },
  {
    storyNumber: "02",
    sectionHeading: "Our Mission",
    storyDescription:
      " We are committed to delivering person-centred care that respects the unique needs of each client.",
  },
  {
    storyNumber: "03",
    sectionHeading: "Our Vision",
    storyDescription:
      "We envision a world where every individual receives the care and support they deserve, allowing them to thrive in their daily lives.",
  },
];

export default function AboutPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <Header />
        <div className="flex flex-col items-center gap-[130px] lg:gap-[97px] md:gap-[97px] sm:gap-[65px]">
          {/* about section */}
          <AboutSection />
          <div className="container-xs lg:px-5 md:px-5">
            <div className="flex flex-col gap-[70px] lg:gap-[70px] md:gap-[52px] sm:gap-[35px]">
              <div className="flex items-center justify-between gap-5 md:flex-col">
                <Img
                  src="images/img_img_680x610.png"
                  alt="Featured Image"
                  className="h-full w-[46%] object-contain md:w-full"
                />
                <div className="flex w-[46%] flex-col items-start md:w-full">
                  <Text
                    size="textlg"
                    as="p"
                    className="mt-3.5 w-full text-[54px] font-light leading-[65px] text-blue_gray-900_01 lg:text-[45px] md:text-[34px] sm:text-[28px]"
                  >
                    <span>Empowering individuals to&nbsp;</span>
                    <span className="font-bold">live their lives</span>
                  </Text>
                  <Text
                    size="textmd"
                    as="p"
                    className="mt-3.5 w-full text-[18px] font-normal leading-7 text-gray-600_01 lg:text-[15px]"
                  >
                    Our commitment to providing outstanding care has made us a trusted name in the industry.
                  </Text>
                  <div className="mr-12 mt-[30px] flex gap-[118px] self-stretch md:mr-0 md:flex-row sm:flex-col">
                    <ServiceFeatures className="w-[44%]" />
                    <ServiceFeatures
                      carePlansText="Dedicated Care Teams"
                      supportServicesText="Regular Health Assessments"
                      safetyText="Family Engagement Program"
                      careSchedulingText="Community Resource Access"
                      wellbeingFocusText="Activity Planning"
                      className="w-[56%]"
                    />
                  </div>
                  <a href="https://www.youtube.com/embed/bv8Fxk0sz7I" target="_blank">
                    <Button
                      shape="round"
                      className="mt-10 min-w-[170px] rounded-[30px] px-[34px] font-extrabold tracking-[1.40px] sm:px-4"
                    >
                      Contact Us
                    </Button>
                  </a>
                </div>
              </div>
              <div className="flex gap-[30px] md:flex-col">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {aboutUsStories.map((d, index) => (
                    <OurStoriesSection {...d} key={"infoList" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>

          {/* why choose us section */}
          <WhyChooseUsSection />
        </div>
        <Footer className="mt-[130px]" />
      </div>
    </>
  );
}

import { Img, Text, Heading } from "../../components";
import React from "react";

export default function TermsConditionsSection() {
  return (
    <>
      {/* terms conditions section */}
      <div>
        <div className="flex items-center justify-end bg-white-a700 md:flex-col">
          <div className="mb-14 flex w-[34%] flex-col items-start gap-3.5 self-end md:w-full md:self-auto md:px-5">
            <a href="#" className="w-[90%] leading-[90px] lg:w-full lg:text-[48px] md:w-full md:text-[48px]">
              <Heading size="heading3xl" as="h1" className="text-[74px] font-extrabold text-blue_gray-900_01">
                Terms & Conditions
              </Heading>
            </a>
            <Text
              size="textmd"
              as="p"
              className="w-[92%] text-[18px] font-normal leading-7 text-gray-600_01 lg:w-full lg:text-[15px] md:w-full"
            >
              Our Terms and Conditions set out the rules and guidelines for using our website and services. By accessing
              or using our platform, you agree to comply with these terms. This includes respecting copyright and
              intellectual property rights, using our services only for lawful purposes, and understanding your
              responsibilities as a user.{" "}
            </Text>
          </div>
          <Img src="images/img_img_5.png" alt="Product Image" className="h-[520px] w-[48%] object-contain md:w-full" />
        </div>
      </div>
    </>
  );
}

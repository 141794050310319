import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  nurseImage?: string;
  headingText?: React.ReactNode;
  descriptionText?: React.ReactNode;
}

export default function ProfessionalProfile({
  nurseImage = "images/img_nurse_1_1.svg",
  headingText = "Qualified Professionals",
  descriptionText = "Fully trained and experienced in delivering high-quality care services.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex justify-center items-start gap-5 flex-1`}>
      <Img src={nurseImage} alt="Nurse Image" className="h-[50px] w-[50px]" />
      <div className="flex flex-1 flex-col items-start self-center">
        <Heading size="headingxs" as="h6" className="text-[18px] font-semibold text-white-a700">
          {headingText}
        </Heading>
        <Text as="p" className="w-full text-[16px] font-normal leading-[25px] text-gray-300">
          {descriptionText}
        </Text>
      </div>
    </div>
  );
}

import { Heading } from "../../components";
import CareServiceList from "../../components/CareServiceList";
import React from "react";

export default function CompassionateCareSection() {
  return (
    <>
      {/* compassionate care section */}
      <div className="flex justify-center px-14 md:px-5 sm:px-4">
        <div className="flex w-[74%] flex-col items-center gap-[38px] lg:w-full md:w-full">
          <div className="container-xs flex flex-col items-start gap-1.5 lg:px-5 md:px-5">
            <Heading size="headingxs" as="h2" className="text-[14px] font-extrabold tracking-[1.40px] text-blue-600_01">
              Compassionate Care When You Need It
            </Heading>
            <Heading
              size="heading2xl"
              as="h3"
              className="text-[54px] font-bold text-blue_gray-900_01 lg:text-[45px] md:text-[34px] sm:text-[28px]"
            >
              Available Services
            </Heading>
          </div>
          <div className="flex flex-col gap-12 self-stretch pl-12">
            <CareServiceList className="sm:flex-col" />
            <CareServiceList
              personalCareImage="images/img_physical_wellbeing.svg"
              personalCareHeading="Dementia Care"
              personalCareDescription="We offer specialised care for individuals living with dementia, focusing on maintaining their dignity and quality of life through tailored support strategies."
              companionshipImage="images/img_stethoscope_1.svg"
              companionshipHeading="Respite Care"
              companionshipDescription="Our respite care services provide temporary relief for family caregivers, ensuring their loved ones receive the care they need while they take a break."
              medicationManagementImage="images/img_follower_1.svg"
              medicationManagementHeading="Nursing Care"
              medicationManagementDescription="Our qualified nurses deliver medical support, including wound care, monitoring chronic conditions, and coordinating with healthcare professionals."
              liveInCareImage="images/img_house_1.svg"
              liveInCareHeading="Household Support"
              liveInCareDescription="We assist with light housekeeping, meal preparation, and grocery shopping, helping clients maintain a clean and comfortable living environment."
              className="mr-4 md:mr-0 sm:flex-col"
            />
          </div>
        </div>
      </div>
    </>
  );
}

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TermsAndConditionsSection from "./TermsAndConditionsSection";
import TermsConditionsSection from "./TermsConditionsSection";
import React from "react";

export default function TermsConditionsPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <Header className="py-5" />
        <div className="flex flex-col ">
          {/* terms conditions section */}
          <TermsConditionsSection />

          {/* terms and conditions section */}
          <TermsAndConditionsSection />
        </div>
        <Footer className="mt-[130px]" />
      </div>
    </>
  );
}

// import { Input, Button, Heading } from "../../components";
// import React from "react";

// export default function RegistrationFormSection() {
//   return (
//     <>
//       {/* registration form section */}
//       <div className="flex justify-center">
//         <div className="container-xs flex justify-center lg:px-5 md:px-5">
//           <div className="flex w-full flex-col items-start justify-center gap-[38px] bg-gray-50 py-[120px] pl-[130px] pr-14 lg:py-8 lg:pl-8 md:p-5 sm:p-4">
//             <Heading
//               size="heading2xl"
//               as="h1"
//               className="text-[54px] font-bold text-blue_gray-900_01 lg:text-[45px] md:text-[34px] sm:text-[28px]"
//             >
//               Get Started Now!
//             </Heading>
//             <div className="mb-2 flex w-[92%] lg:w-full md:w-full">
//               <div className="flex w-full items-start gap-[30px] md:flex-col">
//                 <div className="flex w-full flex-col items-start self-center">
//                   <Input
//                     shape="square"
//                     type="text"
//                     name="Name Input"
//                     placeholder={`Your Name`}
//                     className="self-stretch !border-b px-3"
//                   />
//                   <Input
//                     shape="square"
//                     type="number"
//                     name="Phone Input"
//                     placeholder={`Your Phone No.`}
//                     className="mt-[30px] self-stretch !border-b px-3"
//                   />
//                   <Button
//                     shape="round"
//                     className="mt-20 min-w-[138px] rounded-[30px] px-[34px] font-extrabold tracking-[1.40px] sm:px-4"
//                   >
//                     Submit
//                   </Button>
//                 </div>
//                 <div className="flex w-full flex-col gap-[30px]">
//                   <Input
//                     shape="square"
//                     type="text"
//                     name="Last Name Input"
//                     placeholder={`Last Name`}
//                     className="!border-b px-3"
//                   />
//                   <Input
//                     shape="square"
//                     type="email"
//                     name="Email Input"
//                     placeholder={`Your Email`}
//                     className="!border-b px-3"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import { Input, Button, Heading } from "../../components";
import React from "react";
import { useRegisterForm } from "./useregister";
// import { useRegisterForm } from "./useregister";
// import { useRegisterForm } from "./useRegister";
import TextField from "@mui/material/TextField";

export default function RegistrationFormSection() {
  const { formData, handleChange, submitForm, validationErrors } =
    useRegisterForm();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <>
      {/* registration form section */}
      <div className="flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <div className="flex w-full flex-col items-start justify-center gap-[38px] bg-gray-50 py-[120px] pl-[130px] pr-14 lg:py-8 lg:pl-8 md:p-5 sm:p-4">
            <Heading
              size="heading2xl"
              as="h1"
              className="text-[54px] font-bold text-blue_gray-900_01 lg:text-[45px] md:text-[34px] sm:text-[28px]"
            >
              Get Started Now!
            </Heading>
            <form
              onSubmit={handleSubmit}
              className="mb-2 flex w-[92%] lg:w-full md:w-full"
            >
              <div className="mb-2 flex w-[92%] lg:w-full md:w-full">
                <div className="flex w-full items-start gap-[30px] md:flex-col">
                  <div className="flex w-full flex-col items-start self-center">
                    <div className="flex w-full gap-[30px]">
                      {/* <Input
                    shape="square"
                    type="text"
                    name="firstName"
                    placeholder="Your First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="self-stretch !border-b px-3"
                  />

                  {validationErrors.firstName && (
                    <span className="ml-3 text-red-500">
                      {validationErrors.firstName}
                    </span>
                  )} */}
                      <TextField
                        variant="standard"
                        label="Your Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        error={!!validationErrors.firstName}
                        helperText={validationErrors.firstName}
                        fullWidth
                        // InputProps={{ classes: { root: "self-stretch px-3" } }}
                      />
                      <TextField
                        variant="standard"
                        type="text"
                        name="lastName"
                        label="Last Name"
                        // placeholder="Your Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(validationErrors.lastName)}
                        helperText={
                          validationErrors.lastName
                            ? validationErrors.lastName
                            : " "
                        }
                        className="mt-[30px]"
                      />
                    </div>
                    <div className="flex w-full gap-[30px] mt-[30px]">
                      <TextField
                        variant="standard"
                        type="text"
                        name="phoneNumber"
                        label="Your Phone No."
                        // placeholder="Your Phone No."
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(validationErrors.phoneNumber)}
                        helperText={
                          validationErrors.phoneNumber
                            ? validationErrors.phoneNumber
                            : " "
                        }
                        className="mt-[30px]"
                      />
                      <TextField
                        variant="standard"
                        type="email"
                        name="email"
                        label="Email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(validationErrors.email)}
                        helperText={
                          validationErrors.email ? validationErrors.email : " "
                        }
                        className="mt-[30px]"
                      />
                    </div>
                    {/* <label className="mt-4">
                    <input
                      type="checkbox"
                      checked={isCheckboxSelected}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    I agree to the terms and conditions
                  </label> */}
                    <Button
                      shape="round"
                      className="mt-20 min-w-[138px] rounded-[30px] px-[34px] font-extrabold tracking-[1.40px] sm:px-4"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

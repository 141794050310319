// import { Text, Heading, Img } from "./..";
import { Box, Typography, ThemeProvider, createTheme } from "@mui/material";
import { Heading, Img, Text } from "components";
import React from "react";
import '@fontsource/manrope'; // Use if you have a font package, or skip if using local file

interface Props {
  className?: string;
  offerTitle?: React.ReactNode;
  offerDescription?: React.ReactNode;
  stepOneNumber?: React.ReactNode;
  stepOneHeading?: React.ReactNode;
  stepOneDescription?: React.ReactNode;
  stepTwoNumber?: React.ReactNode;
  stepTwoHeading?: React.ReactNode;
  stepTwoDescription?: React.ReactNode;
  stepThreeNumber?: React.ReactNode;
  stepThreeHeading?: React.ReactNode;
  stepThreeDescription?: React.ReactNode;
}

export default function ServicesSection2({
  offerTitle = "We Offer",
  offerDescription,
  stepOneNumber = "01",
  stepOneHeading = "24/7 Tailored Care",
  stepOneDescription = "Our experienced team offers personalised support around the clock, ensuring your loved ones receive the care they need whenever they need it.",
  stepTwoNumber = "02",
  stepTwoHeading = "Frequent Health Assessments",
  stepTwoDescription = "We perform regular evaluations of our residents’ health and well-being, ensuring that any changes in their needs are promptly addressed.",
  stepThreeNumber = "03",
  stepThreeHeading = "Secure environment",
  stepThreeDescription = "Our comprehensive safety protocols provide a secure environment, giving families peace of mind knowing their loved ones are well-protected.",
  ...props
}: Props) {
  const theme = createTheme({
    typography: {
      fontFamily: 'Manrope, Arial, sans-serif',
    },
  });

  return (
    <div {...props} className={`${props.className} flex items-center flex-1 md:flex-col`}>
      <Img
        src="images/img_img_980x960.png"
        alt="Main Image"
        className="h-[960px] lg:h-auto w-[50%] object-contain md:w-full order-first"
      />
      <div className="h-[956px] lg:h-auto w-[50%] object-contain md:w-full">
        <div className="flex flex-1 flex-col items-start self-end bg-indigo-900 py-[150px] pl-[89px] lg:py-8 lg:pl-8 md:self-stretch md:p-5 sm:p-4">
          <Heading
            size="headingxs"
            as="p"
            className="text-[14px] font-extrabold tracking-[1.40px] text-blue-600_01"
          >
            {offerTitle}
          </Heading>
          <ThemeProvider theme={theme}>
            <Box>
              <Typography
                variant="h1"
                sx={{
                  mt: 2.5,
                  fontSize: { xs: '28px', sm: '34px', md: '45px', lg: '54px' },
                  fontWeight: 300, // Light font weight
                  lineHeight: { xs: '1.2', lg: '65px' },
                  color: 'white',
                  display: 'inline',
                }}
              >
                Personalised&nbsp;
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '28px', sm: '34px', md: '45px', lg: '54px' },
                  fontWeight: 700, // Bold font weight
                  lineHeight: { xs: '1.2', lg: '65px' },
                  color: 'white',
                  display: 'inline',
                }}
              >
                Care Plans
              </Typography>
            </Box>
          </ThemeProvider>
          <div className="mb-[108px] mt-[34px] flex w-[94%] flex-col gap-[22px] lg:w-full md:w-full">
            <div className="flex flex-col gap-2.5">
              <div className="flex items-start justify-center gap-6 md:flex-col">
                <Heading
                  size="headinglg"
                  as="h5"
                  className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-white-a700 text-center text-[22px] font-bold text-blue-600_01 lg:text-[18px]"
                >
                  {stepOneNumber}
                </Heading>
                <div className="flex flex-1 flex-col items-start self-center md:self-stretch">
                  <Heading
                    size="headingmd"
                    as="h6"
                    className="text-[18px] font-bold text-white-a700 lg:text-[15px]"
                  >
                    {stepOneHeading}
                  </Heading>
                  <Text
                    as="p"
                    className="w-[90%] text-[16px] font-normal leading-[25px] text-gray-300 lg:w-full lg:text-[13px] md:w-full"
                  >
                    {stepOneDescription}
                  </Text>
                </div>
              </div>
              <div className="flex items-start justify-center gap-6 md:flex-col">
                <Heading
                  size="headinglg"
                  as="h5"
                  className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-white-a700 text-center text-[22px] font-bold text-blue-600_01 lg:text-[18px]"
                >
                  {stepTwoNumber}
                </Heading>
                <div className="flex flex-1 flex-col items-start gap-2 md:self-stretch">
                  <Heading
                    size="headingmd"
                    as="h6"
                    className="text-[18px] font-bold text-white-a700 lg:text-[15px]"
                  >
                    {stepTwoHeading}
                  </Heading>
                  <Text
                    as="p"
                    className="text-[16px] font-normal text-gray-300 lg:text-[13px]"
                  >
                    {stepTwoDescription}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex items-start justify-center gap-6 md:flex-col">
              <Heading
                size="headinglg"
                as="h5"
                className="flex h-[60px] w-[60px] items-center justify-center rounded-[30px] bg-white-a700 text-center text-[22px] font-bold text-blue-600_01 lg:text-[18px]"
              >
                {stepThreeNumber}
              </Heading>
              <div className="flex flex-1 flex-col items-start gap-2 md:self-stretch">
                <Heading
                  size="headingmd"
                  as="h6"
                  className="text-[18px] font-bold text-white-a700 lg:text-[15px]"
                >
                  {stepThreeHeading}
                </Heading>
                <Text
                  as="p"
                  className="text-[16px] font-normal text-gray-300 lg:text-[13px]"
                >
                  {stepThreeDescription}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
